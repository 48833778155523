import Link from "next/link"
import { Text } from "../styles/BodyStyle"
import styles  from '../styles/ErrorScreen.module.css'
const ErrorNotFound = () => {
    return ( <>
    <div className={styles.container}>
        <Text size={`50px`} weight="700" color="var(--primary)">
            Oops!
        </Text>
    <Text size={`25px`} weight="500"> Page Not Found</Text>
    <Link href="/"><a><Text color="var(--secondary)"></Text>Go back home</a></Link>
    </div>
    </> )
}
 
export default ErrorNotFound